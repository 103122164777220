import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { RIGHTS, T, TB, TC, URL } from "../../../Constants";

//#region Types
const CT = G.CellsTypes;
type TableRef = G.TableRef<DataEntry>;
type TableProps = G.TableProps<DataEntry>;
type ColParams = G.ColDefParams<DataEntry>;
type DataEntry = T.API.Utils.Energy.DataEntry;
type SetEntries = (setter: Parameters<React.Dispatch<React.SetStateAction<DataEntry[]>>>[0], update_rows?: boolean, status?: T.AsyncStates) => void;

export type DataChartProps = {
    /** The dataset to get data from */
    dataset: T.DataSet;
    /** Show the form in a popup */
    popup?: boolean;
    /** Props for the modal */
    modalProps?: Omit<M.BlankModalProps, "footer" | "showHeader" | "children">;
}

type Config = {
    /** The time to get data from and to */
    time: T.NRJ.TimeSelection;
}
//#endregion

const DataChart: React.FC<DataChartProps> = props => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    const [copy] = H.useClipBoard();
    const [{ userId }] = H.useAuth();
    const grid_ready = H.useBoolean();
    const rows = React.useRef<DataEntry[]>([]);
    const grid_ref = React.useRef<TableRef>(null);
    const [reload, set_reload] = React.useState(1);
    const [html, setHtml, htmlStatus] = H.useAsyncState("");
    const [status, set_status] = React.useState<T.AsyncStates>("load");
    const [config, setConfig] = React.useState<Config>({ time: { interval: "15 DAY" } });
    const [{ dropdown, currentFilters }, { setFilters }] = H.useFavorite<Config>({ origin: "datasets_charts", variant: "info" });

    const time = React.useMemo(() => TB.getFromToUnix(config.time), [config.time]);
    const strTime = React.useMemo(() => ({ to: new Date(time.to).toISOString(), from: new Date(time.from).toISOString() }), [time])

    // Update the rows ref, and can also update the grid and the status
    const set_rows = React.useCallback<SetEntries>((setter, update_rows = false, status) => {
        let updated_rows: DataEntry[] = [];
        if (typeof setter === "function") updated_rows = setter(rows.current);
        else updated_rows = setter;
        rows.current = updated_rows;
        if (status) set_status(status);
        if (update_rows && grid_ref.current?.grid) grid_ref.current.grid.api.setRowData(updated_rows);
    }, [set_status]);

    // Load the dataset entries
    React.useEffect(() => {
        let isSubscribed = true;
        if (!grid_ready.value) return;
        else if (props.dataset.src === "calculated") set_rows([], true, "done");
        else S.getEntries({ dataset: props.dataset._id, unix: time })
            .then(({ data }) => isSubscribed && set_rows(data, true, "done"))
            .catch(() => isSubscribed && set_rows([], true, "error"));
        return () => {
            isSubscribed = false;
            set_status("load");
        };
    }, [set_rows, set_status, props.dataset.src, props.dataset._id, reload, time, grid_ready.value]);

    // Load the HTML graph
    React.useEffect(() => {
        let isSubscribed = true;
        let params = { ...strTime, dataGraph: 1, indexGraph: 0, lang: lg.prop, heatmapGraph: 1, entity: props.dataset._id };
        S.html_report({ template: "CompteurOK", params })
            .then(({ data }) => isSubscribed && setHtml(data, "done"))
            .catch(() => isSubscribed && setHtml("", "error"));
        return () => {
            isSubscribed = false;
            setHtml("", "load");
        };
    }, [setHtml, strTime, lg.prop, props.dataset._id, reload]);

    //#region Favorite
    React.useEffect(() => {
        setFilters(config)
    }, [config, setFilters]);

    React.useEffect(() => {
        if (currentFilters) setConfig(currentFilters)
    }, [currentFilters]);
    //#endregion

    //#region Rights
    const can_edit = React.useMemo(() => {
        // Only allow editing of automatic and manual datasets on the production machine
        if (URL.IP_DIGITS === "95") return props.dataset?.src === "automatic" || props.dataset?.src === "manual"
        else return props.dataset?.src === "manual";
    }, [props.dataset]);

    const allowed = React.useMemo(() => ({
        own: can_edit && rights.isRightAllowed(RIGHTS.NRJ.WRITE_OWN_DATA, props.dataset?.origin),
        anon: can_edit && rights.isRightAllowed(RIGHTS.NRJ.WRITE_ANON_DATA, props.dataset?.origin),
        other: can_edit && rights.isRightAllowed(RIGHTS.NRJ.WRITE_OTHER_DATA, props.dataset?.origin),
    }), [rights, props.dataset, can_edit]);

    const canEditRow = React.useCallback((row: DataEntry) => {
        // Can only edit automatic and manual datasets
        if (props.dataset?.src !== "automatic" && props.dataset?.src !== "manual") return false;
        // Check if the 'owner' of the data is anonymous or the current user
        let is_anon = !TB.mongoIdValidator(row.owner);
        let is_owner = !is_anon && row.owner === userId;
        // Check if the user has the right to edit the data, based on the ownership of the data
        return (is_owner && allowed.own) || (is_anon && allowed.anon) || (!is_owner && allowed.other);
    }, [allowed, userId, props.dataset]);
    //#endregion

    const configBar = React.useMemo(() => <C.Flex alignItems="center" justifyContent="between">
        <C.TimeSelector
            to={config.time.to}
            from={config.time.from}
            interval={config.time.interval}
            onChangeDatePicker={time => setConfig(p => ({ ...p, time }))}
            onChangeInterval={interval => setConfig(p => ({ ...p, time: { interval } }))}
        />
        {dropdown}
    </C.Flex>, [config, dropdown]);

    const charts = React.useMemo(() => <div className="p-2 border rounded my-2">
        <C.Spinner min_load_size="400px" status={htmlStatus} children={<C.HtmlText html={html} />} />
    </div>, [htmlStatus, html]);

    //#region Table
    const importManualData = React.useCallback(() => {
        const params: Parameters<typeof M.renderExcelMapper>[0] = {
            raw: true,
            invert: true,
            allowIgnoring: true,
            allowSwapping: false,
            title: lg.getStaticText(TC.EXCEL_DATA_IMPORT_WITH_UNIT, props.dataset.unit || TC.EXCEL_DATA_IMPORT_NO_UNIT),
            columnFormat: {
                time: {
                    type: "date",
                    required: true,
                    no_convert_date: true,
                    label: lg.getStaticText(TC.DATASET_COL_TIME),
                },
                value: {
                    type: "number",
                    required: true,
                    label: lg.getStaticText(TC.DATASET_COL_VALUE),
                }
            },
        };

        M.renderExcelMapper(params).then(results => {
            if (Array.isArray(results)) {
                let entries = results as Record<"time" | "value", string>[];
                let times = entries.map(e => e.time);

                if (entries.length === 0) M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_EMPTY_IMPORT });
                else {
                    let format_promise = new Promise<"cancel" | { value: number, time: string }[]>(resolve => {
                        if (times.some((t: any) => !(t instanceof Date))) M.askTimeFormat({ examples: times }).then(format => {
                            if (format) {
                                let parsedEntries = entries.map(e => ({
                                    value: TB.getNumber(e.value),
                                    time: TB.parseDate(e.time, format.format, format.timezone, format.timezone_data)?.toISOString?.(),
                                })).filter(e => !!e.time);
                                resolve(parsedEntries);
                            }
                            else resolve("cancel");
                        });
                        else resolve(entries.map(e => ({ value: TB.getNumber(e.value), time: (e.time as any)?.toISOString?.() || null })));
                    });

                    format_promise.then(parsedEntries => {
                        if (parsedEntries !== "cancel") {
                            // Start the grid loading
                            set_status("load");

                            S.importRecords({ dataset: props.dataset._id, entries: parsedEntries })
                                .then(({ data }) => {
                                    // Tell the user some errors occurred
                                    if (data.failed.length > 0) {
                                        // Show Error message
                                        M.renderAlert({ type: "warning", message: TC.FAIL_DATA_IMPORT });
                                        // User download a file of the values that were not imported
                                        TB.jsonToExcel(data.failed, "IMPORT_FAIL", "sheet");
                                    }
                                    // Tell the users data was inserted, but there were some duplicates
                                    if (data.duplicates.length > 0) {
                                        copy(data.duplicates.join("\n"));
                                        M.renderAlert({ type: "warning", message: TC.FAIL_DATA_IMPORT_DUPLICATE });
                                        M.renderBlankModal({
                                            size: "md",
                                            title: TC.DATA_INSERT_DUPLICATES,
                                            children: <>{data.duplicates.map((d, i) => <div key={i}>{d}</div>)}</>,
                                        });
                                    }
                                    if (data.results.length > 0) {
                                        // Add the new rows to the reference
                                        set_rows(p => p.concat(data.results));
                                        // Add the new rows to the grid
                                        grid_ref.current.grid.api.applyTransaction({ add: data.results });
                                    }
                                })
                                .catch(M.Alerts.updateError)
                                // Stop the grid loading
                                .finally(() => set_status("done"));
                        }
                    });
                }
            }
        });
    }, [props.dataset._id, props.dataset.unit, lg, copy, set_rows]);

    const auto_correct = React.useMemo(() => ({
        allow: props.dataset.src === "automatic" && allowed.anon && props.dataset?.tag?.id && props.dataset?.tag?.station,
        execute: () => {
            let pairs = [{ station: props.dataset.tag.station, tag: props.dataset.tag?.id }];
            M.askDataCorrect({ dataset_name: props.dataset.name, pairs: { id: props.dataset.tag.id, station: props.dataset.tag.station }, dataset_id: props.dataset._id }).then(response => {
                let params: Parameters<typeof S.assessCorrections>[0] = null;
                if (response === "accept") params = { pairs, state: "accept", dataset: props.dataset._id };
                else params = { pairs, state: "reject", dataset: props.dataset._id };

                let unmount = M.renderLoader();
                S.assessCorrections(params).then(() => {
                    // Force the reload of the data
                    if (params.state !== "reject") set_reload(p => p + 1);
                })
                    .catch(M.Alerts.updateError)
                    .finally(unmount);
            });
        }
    }), [allowed.anon, props.dataset]);

    const entries = React.useMemo(() => ({
        export: () => {
            let to_export = rows.current.map(i => ({ value: i.new_value, time: moment(i.time).format("DD/MM/YY HH:mm:SS") }));
            TB.jsonToExcel(to_export, "Data", "Data");
        },
        delete: (row: DataEntry) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) {
                    // Start the grid loading
                    set_status("load");
                    // Call to backend to remove the value
                    S.removeDataEntry({ dataset: props.dataset._id, time: row.time, aggregate: row.aggregate_id })
                        .then(({ data }) => {
                            if (data === "not_found") M.renderAlert({ type: "warning", message: TC.DATA_CHARTS_FAILED_DELETE })
                            else {
                                // Update the row in the reference, remove the original row and replace it with the deleted one
                                set_rows(p => p.map(i => i.id !== row.id ? i : data));
                                // Update the row in the grid
                                grid_ref.current.grid.api.applyTransaction({ add: [data], remove: [row] });
                            }
                        })
                        .catch(M.Alerts.deleteError)
                        // Stop the grid loading
                        .finally(() => set_status("done"));
                }
            });
        },
        restore: (row: DataEntry) => {
            M.askConfirm({ text: TC.DATA_CHARTS_RESTORE_LABEL, title: TC.DATA_CHARTS_RESTORE_TITLE }).then(confirmed => {
                if (confirmed) {
                    // Start the grid loading
                    set_status("load");
                    S.restoreDataEntry({ dataset: props.dataset._id, time: row.time, aggregate: row.aggregate_id })
                        .then(({ data }) => {
                            if (data === "overwritten") M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_OVERWRITTEN });
                            else {
                                // Update the row in the reference, remove the original row and replace it with the restored one
                                set_rows(p => p.map(i => i.id !== row.id ? i : data));
                                // Update the row in the grid
                                grid_ref.current.grid.api.applyTransaction({ add: [data], remove: [row] });
                            }
                        })
                        .catch(M.Alerts.updateError)
                        // Stop the grid loading
                        .finally(() => set_status("done"));
                }
            });
        },
        add: () => {
            M.renderEntryFormModal({ askDate: true, dataset: props.dataset._id }).then(entry => {
                if (entry) {
                    // Start the grid loading
                    set_status("load");
                    // Send the update
                    S.addManualEntry(entry as any)
                        .then(({ data }) => {
                            if (data === "duplicate") M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_DUPLICATE });
                            else {
                                // Add the data to the reference
                                set_rows(p => p.concat(data));
                                // Add the data to the grid
                                grid_ref.current.grid.api.applyTransaction({ add: [data] });
                            }
                        })
                        // Error happened
                        .catch(M.Alerts.updateError)
                        // Stop the grid loading
                        .finally(() => set_status("done"));
                }
            });
        },
        cancel_period: (row: DataEntry) => {
            let time_str = moment(row.period_data.from).format("DD/MM/YY HH:mm:SS") + " - " + moment(row.period_data.to).format("DD/MM/YY HH:mm:SS");
            let message = lg.getStaticText(TC.DATA_CORRECT_CANCEL_PERIOD, time_str);

            M.askConfirm({ text: message }).then(confirmed => {
                if (confirmed) {
                    // Start the grid loading
                    set_status("load");
                    // Send the update
                    S.cancelPeriodUpdate({ dataset: props.dataset._id, period: row.period_data, aggregate: row.aggregate_id })
                        .then(({ data }) => {
                            if (data === "not_found") {
                                M.Alerts.loadError();
                                set_status("done");
                            }
                            else S.getEntries({ dataset: props.dataset._id, unix: time })
                                .then(({ data }) => set_rows(data, true, "done"))
                                .catch(() => set_rows([], true, "error"));
                        })
                        // Error happened
                        .catch(e => {
                            M.Alerts.updateError(e);
                            set_status("done");
                        });
                }
            });
        },
    }), [set_rows, set_status, props.dataset._id, time, lg]);

    const grid_buttons = React.useMemo<ColParams>(() => ({
        buttonProps: (row: DataEntry) => {
            if (!row) return;
            // Check if the user can edit the row
            let canEdit = canEditRow(row);
            if (!canEdit) return undefined;
            // Restore a period
            else if (row.period && !row.delete_date) return { size: "sm", icon: "trash-restore", variant: "info", onClick: () => entries.cancel_period(row) };
            else if (row.delete_date) return { size: "sm", icon: "trash-restore", variant: "info", onClick: () => entries.restore(row) };
            else return { size: "sm", icon: "times", variant: "danger", onClick: () => entries.delete(row) };
        }
    }), [canEditRow, entries]);

    const onValueChange = React.useCallback<TableProps["onValueChange"]>(event => {
        let row = event.data,
            new_value = event.newValue,
            old_value = event.oldValue,
            field = event.colDef.field as keyof DataEntry;

        if (!row) return;
        // Don't edit inside a period
        else if (row.period) {
            M.renderAlert({ type: "warning", message: TC.DATA_CHARTS_EDIT_PERIOD });
            return;
        }
        // Don't edit a deleted row
        else if (row.delete_date) {
            M.renderAlert({ type: "warning", message: TC.DATA_CHARTS_EDIT_DELETE });
            return;
        }
        else if (!canEditRow(row)) {
            M.renderAlert({ type: "warning", message: TC.DATA_CHARTS_EDIT_RIGHT });
            return;
        }
        // Changed the "new_value" field
        else if (field === "new_value") {
            M.askConfirm({ title: TC.DATA_ENTRY_ASK_NOTE_TITLE, text: TC.DATA_ENTRY_ASK_NOTE_MSG, yesText: TC.GLOBAL_YES, noText: TC.GLOBAL_NO }).then(confirmed => {
                type PartialEntry = Pick<Awaited<ReturnType<typeof M.renderEntryFormModal>>, "value" | "note" | "picture">;

                const entry_promise = new Promise<"cancel" | PartialEntry>(resolve => {
                    if (typeof confirmed !== "boolean") resolve("cancel");
                    else if (!confirmed) resolve({ value: new_value });
                    else M.renderEntryFormModal({ dataset: props.dataset._id, value: { value: new_value } }).then(entry => resolve(entry ? entry : "cancel"));
                });

                entry_promise.then(entry => {
                    if (entry !== "cancel") {
                        // Start the grid loading
                        set_status("load");
                        // Create the params to update the entry
                        let params = {
                            time: row.time,
                            note: entry.note,
                            value: entry.value,
                            old_value: old_value,
                            picture: entry.picture,
                            dataset: props.dataset._id,
                            aggregate: row.aggregate_id,
                        } as Parameters<typeof S.editRecordEntry>[0];
                        // Update the entry in the database
                        S.editRecordEntry(params)
                            .then(({ data }) => {
                                // Update the rows reference
                                set_rows(p => p.map(i => i.id === data.id ? data : i));
                                // Update the grid
                                grid_ref.current.grid.api.applyTransaction({ update: [data] });
                            })
                            .catch(M.Alerts.updateError)
                            // Stop the grid loading
                            .finally(() => set_status("done"));
                    }
                });
            });
        }
        // Changed the "date" field
        else if (field === "time") {
            // Start the grid loading
            set_status("load");

            let params = {
                dataset: props.dataset._id,
                aggregate: row.aggregate_id,
                new_time: new Date(new_value).toISOString(),
                old_time: new Date(old_value).toISOString(),
            } as Parameters<typeof S.editRecordTime>[0];

            S.editRecordTime(params).then(result => {
                // Update the rows reference
                set_rows(p => {
                    let new_items = p.map(i => i.id === row.id ? result.data.removed : (i.id === result.data?.update?.id ? result.data.update : i));
                    // Add the updated item if it's not already in the list
                    if (result.data.created) new_items.push(result.data.update);
                    return new_items;
                });
                // Update the grid
                let to_remove = [row], to_add = [result.data.removed], to_update = [];
                if (result.data.created || !rows.current.some(i => i.id === result.data.update?.id)) to_add.push(result.data.update);
                else to_update.push(result.data.update);
                // Apply the transaction
                grid_ref.current.grid.api.applyTransaction({ remove: to_remove, add: to_add, update: to_update });
            })
                .catch(M.Alerts.updateError)
                .finally(() => set_status("done"));
        }
    }, [set_status, set_rows, canEditRow, props.dataset._id]);

    const editable = React.useCallback<Exclude<G.ColDef<DataEntry>["editable"], boolean>>((params) => {
        // Type of dataset makes it not editable
        if (!can_edit) return false;
        // Can't edit if row is deleted
        else if (params.data.delete_date) return false;
        else return true;
    }, [can_edit]);

    const columns = React.useMemo<TableProps["columns"]>(() => [
        { field: "buttons", headerName: " ", type: CT.TYPE_ACTION_BUTTON, params: grid_buttons },
        { field: "time", headerName: TC.DATASET_COL_TIME, type: CT.TYPE_DATE, editable: editable, sort: "desc", sortIndex: 0, params: { isDateTime: true } },
        { field: "old_value", headerName: TC.DATASET_COL_OLD_VAL, type: CT.TYPE_NUMBER, editable: false },
        { field: "new_value", headerName: TC.DATASET_COL_NEW_VAL, type: CT.TYPE_NUMBER, editable: editable },
        { field: "aggregate_label", headerName: TC.DATASET_AGGREGATION, editable: false },
        { field: "edit_date", headerName: TC.DATASET_COL_EDIT_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
        { field: "edit_user", headerName: TC.DATASET_COL_EDIT_USER },
        { field: "delete_date", headerName: TC.DATASET_COL_DELETE_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
        { field: "delete_user", headerName: TC.DATASET_COL_DELETE_USER },
        { field: "note", headerName: TC.DATA_ENTRY_NOTE },
        { field: "picture", headerName: TC.DATA_ENTRY_PICTURE, type: CT.TYPE_PICTURES, params: { layout: "profile" } },
    ], [grid_buttons, editable]);

    const auto_fit = React.useMemo<TableProps["autoFit"]>(() => ["time", "old_value", "new_value", "aggregate_label", "buttons"], []);

    // Refs for the toolbar buttons
    const entries_ref = React.useRef(entries);
    const import_ref = React.useRef(importManualData);
    const auto_correct_ref = React.useRef(auto_correct);
    React.useImperativeHandle(entries_ref, () => entries, [entries]);
    React.useImperativeHandle(import_ref, () => importManualData, [importManualData]);
    React.useImperativeHandle(auto_correct_ref, () => auto_correct, [auto_correct]);

    const toolbar = React.useMemo<TableProps["extra_buttons"]>(() => {
        let buttons = [] as T.OnlyArray<TableProps["extra_buttons"]>;
        // Buttons to add data
        if (can_edit) buttons.push(
            // Import from file
            {
                onClick: import_ref.current,
                label: lg.getStaticText(TC.DATASET_IMPORT_DATA),
                icon: { element: "<i class='fa fa-file-import me-2'></i>" },
            },
            // Create a new entry
            {
                onClick: entries_ref.current.add,
                label: lg.getStaticText(TC.DATASET_CREATE_DATA),
                icon: { element: "<i class='fa fa-plus me-2'></i>" },
            },
        );
        // Export the data
        buttons.push({
            onClick: entries_ref.current.export,
            label: lg.getStaticText(TC.EXPORT),
            icon: { element: "<i class='fa fa-file-excel me-2'></i>" },
        });
        // Auto-correct the data
        if (auto_correct_ref.current.allow) buttons.push({
            onClick: auto_correct_ref.current.execute,
            label: lg.getStaticText(TC.AUTO_CORRECT_DATA),
            icon: { element: "<i class='fa fa-robot me-2'></i>" },
        });
        return buttons;
    }, [lg, can_edit]);

    React.useEffect(() => {
        if (grid_ready.value) {
            if (status === "load") grid_ref.current.grid.api.showLoadingOverlay();
            else grid_ref.current.grid.api.hideOverlay();
        }
    }, [status, grid_ready.value]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modalProps,
            disableEscapeKeyDown: true,
            size: props.modalProps?.size || "md",
            title: props.modalProps?.title || TC.DATASET_CHART_MODAL_TITLE,
            maxBodyHeight: props.modalProps?.isFullScreen ? "" : props.modalProps?.maxBodyHeight || "80vh",
        } as M.BlankModalProps : null,
        <>
            {configBar}
            {charts}
            <C.Flex direction="column" style={{ height: "50vh", maxHeight: "75vh" }}>
                <G.Table<DataEntry>
                    pagination
                    ref={grid_ref}
                    status={status}
                    columns={columns}
                    api_provided_rows
                    autoFit={auto_fit}
                    extra_buttons={toolbar}
                    adaptableId="data_chart"
                    columns_base="all_but_edit"
                    getRowId={row => row.data.id}
                    onValueChange={onValueChange}
                    onReadyGrid={grid_ready.setTrue}
                />
            </C.Flex>
        </>
    );
}

export default DataChart;